import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LoginService } from '../../../services/login.service';
import { isPlatformBrowser } from '@angular/common';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    constructor(
        private login: LoginService,
        @Inject(PLATFORM_ID) protected platformId: any,
    ) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        req = req.clone({ withCredentials: true });

        if (this.login.isLoggedIn) {
            req = req.clone({
                params: req.params.set('_auth', '1'),
            });
        }

        return next.handle(req).pipe(
            catchError((error) => {
                if (error instanceof HttpErrorResponse) {
                    /** Для 401 пробуем повторить запрос без _auth */
                    if (error.status === 401) {
                        this.login.logout(false);

                        /** Создаем новый запрос без _auth параметра */
                        const retryReq = req.clone({
                            params: req.params.delete('_auth'),
                        });
                        return next.handle(retryReq).pipe(
                            /** если ещё раз по этому запросу ловим ошибку - выходим с переадресацией на главную*/
                            catchError((retryError) => {
                                if (retryError instanceof HttpErrorResponse &&
                                    (retryError.status === 401 || retryError.status === 403)) {
                                    if (this.login.isLoggedIn) {
                                        this.login.logout(true);
                                    }
                                }
                                return throwError(() => retryError);
                            }),
                        );
                    }

                    /** для 403 сразу выходим с переадресацией на главную*/
                    if (error.status === 403 && this.login.isLoggedIn) {
                        const isBrowser = isPlatformBrowser(this.platformId);
                        this.login.logout(isBrowser);
                    }
                }
                return throwError(() => error);
            }),
        );
    }

}
