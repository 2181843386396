import { Component, Inject, NgZone, OnInit, PLATFORM_ID } from '@angular/core';
import { LanguageService } from './modules/language/services/language.service';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { filter, startWith, take } from 'rxjs/operators';
import { NavigationEnd, Router } from '@angular/router';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { AccountApi } from './api';
import { GarageService } from './services/garage.service';
import { HttpCacheService } from './services/http-cache.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {

    constructor(
        @Inject(DOCUMENT) private document: Document,
        @Inject(PLATFORM_ID) protected platformId: any,
        private router: Router,
        private zone: NgZone,
        private language: LanguageService,
        private localizeRouterService: LocalizeRouterService,
        public account: AccountApi,
        private httpCache: HttpCacheService,
        private garage: GarageService,  //не убирать
    ) {
        localizeRouterService.hooks.initialized.subscribe(value => {
            if (value && isPlatformBrowser(platformId)) {
                this.setLanguage();
            }
        });
    }

    setLanguage() {
        const cachedLang = localStorage.getItem('plang');
        if (cachedLang && cachedLang != this.localizeRouterService.parser.currentLang
            && this.language.all.find(value => value.code === cachedLang)) {
            this.localizeRouterService.changeLanguage(cachedLang);
        }
    }

    ngOnInit(): void {

        if (isPlatformBrowser(this.platformId)) {

            this.zone.onStable.pipe(take(1)).subscribe(() => {
                console.log('zone is stable');
                this.httpCache.disable();
            });

            this.zone.runOutsideAngular(() => {
                this.router.events.pipe(
                    filter(event => event instanceof NavigationEnd),
                    startWith(this.router),
                ).subscribe(() => {
                    /**
                     * отправка активности после завершения навигации куда-либо
                     */
                    this.account.sendLastActivity().pipe(take(1)).subscribe();
                });
            });
        }
    }
}
