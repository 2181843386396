<div class="sign-form-logo d-none d-lg-flex">
    <img [src]="'assets/images/logo_full.png'" class="img-fluid" alt="" />
</div>
<div class="sign-form-content p-3 px-sm-5 py-sm-4">
    <ul class="sign-form-content__list">
        <li
            class="sign-form-content__item"
            [class.sign-form-content__item--active]="currentTab === tabSignUp"
        >
            <span (click)="setCurrentTab(tabSignUp)">
                {{ "HEADER_REGISTER" | translate | uppercase }}
            </span>
        </li>
        <li
            class="sign-form-content__item"
            [class.sign-form-content__item--active]="currentTab === tabSignIn"
        >
            <span (click)="setCurrentTab(tabSignIn)">
                {{ "HEADER_LOGIN" | translate | uppercase }}
            </span>
        </li>
    </ul>

    <div class="sign-form-content__body">
        <ng-container *ngIf="currentTab === tabSignIn">
            <app-sign-in-form (loginSuccess)="loginSuccess()"></app-sign-in-form>
        </ng-container>

        <ng-container *ngIf="currentTab === tabSignUp">
            <app-sign-up-form (registerSuccess)="loginSuccess()"></app-sign-up-form>
        </ng-container>
    </div>
</div>
