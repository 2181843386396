import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { HttpCacheService } from '../../../services/http-cache.service';

@Injectable()
export class CachePreventionInterceptor implements HttpInterceptor {

    constructor(private cacheControl: HttpCacheService) {
    }

    intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        if (req.url.includes('/static/') || req.url.includes('/assets/')) {
            return next.handle(req);
        }

        // Добавляем timestamp после гидратации
        if (this.cacheControl.isHydrated) {
            const timestamp = new Date().getTime();
            const clonedReq = req.clone({
                params: req.params.set('_t', timestamp.toString()),
            });
            return next.handle(clonedReq);
        }

        return next.handle(req);
    }
}
