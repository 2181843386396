import {
    Component,
    inject,
    Inject,
    Injector, NgZone,
    OnDestroy,
    OnInit,
    PLATFORM_ID,
    runInInjectionContext,
} from '@angular/core';
import { filter, takeUntil } from 'rxjs/operators';
import { interval, Subject } from 'rxjs';
import { TranslateUrlService } from '../../services/translate-url.service';
import { LanguageService } from '../../modules/language/services/language.service';
import { environment } from '../../../environments/environment';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { RouteMetaService } from '../../services/route-meta.service';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';

@Component({
    selector: 'app-root',
    templateUrl: './root.component.html',
    styleUrls: ['./root.component.scss'],
})
export class RootComponent implements OnInit, OnDestroy {
    destroy$: Subject<void> =  new Subject<void>();
    private injector = inject(Injector);

    updateAvailable = false;

    constructor(
        public translateUrl: TranslateUrlService,
        public language: LanguageService,
        @Inject(DOCUMENT) private document: Document,
        private meta: RouteMetaService,
        @Inject(PLATFORM_ID) private platformId: any,
        private zone: NgZone,
    ) { }

    ngOnInit(): void {

        this.translateUrl.currentUrl$
            .pipe(takeUntil(this.destroy$))
            .subscribe(value => this.setLinkAlternateTags(value));

        this.translateUrl.currentLang$
            .pipe(takeUntil(this.destroy$))
            .subscribe(value => {
                this.document.documentElement.setAttribute('lang', value);
                this.meta.noindex(value === 'en');
            });

        this.update();
    }

    update() {
        if (isPlatformBrowser(this.platformId)) {
            /**
             * Обнаруживает новую версию на сервере
             */
            runInInjectionContext(this.injector, () => {
                const swUpdate = inject(SwUpdate);

                console.log('sw update enabled', swUpdate.isEnabled);

                if (swUpdate.isEnabled) {
                    swUpdate.versionUpdates
                        .pipe(
                            takeUntil(this.destroy$),
                            filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'))
                        .subscribe(() => {
                            console.log('new version found');
                            this.updateAvailable = true;
                        });

                    this.checkForUpdate(swUpdate);
                }
            });
        }
    }

    checkForUpdate(swUpdate: SwUpdate) {
        this.zone.runOutsideAngular(() => {
            //интервал проверки обновления каждые 10 мин
            interval(10 * 60 * 1000)
                .pipe(takeUntil(this.destroy$))
                .subscribe(() => {
                    this.zone.run(() => {
                        swUpdate.checkForUpdate().then();
                    })
                });
        });
    }

    reloadApp() {
        this.updateAvailable = false;
        window.location.reload();
    }

    dismissUpdate() {
        this.updateAvailable = false;
    }

    setLinkAlternateTags(currentUrl: string) {
        // Удалить все существующие теги <link rel="alternate">
        const existingTags = this.document.head.querySelectorAll('link[rel="alternate"]');
        existingTags.forEach(tag => {
            this.document.head.removeChild(tag);
        });

        // Добавить новые теги <link rel="alternate">
        for(const item of this.language.all) {
            if (item === this.language.default) {
                this.createLinkAlternateElement(item.code, `${environment.mainHost}${currentUrl}`);
                this.createLinkAlternateElement('x-default', `${environment.mainHost}${currentUrl}`);
            } else {
                this.createLinkAlternateElement(item.code, `${environment.mainHost}/${item.code}${currentUrl}`);
            }
        }
    }

    createLinkAlternateElement(code: string, href: string): void {
        const link = this.document.createElement('link');
        link.setAttribute('rel', 'alternate');
        link.setAttribute('hreflang', code);
        link.setAttribute('href', href);
        this.document.head.appendChild(link);
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }
}
