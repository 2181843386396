import { CanActivateFn } from '@angular/router';
import { AccountApi } from '../../../api';
import { inject } from '@angular/core';
import { catchError, take } from 'rxjs/operators';
import { map, of } from 'rxjs';

export const checkUserGuard: CanActivateFn = (route, state) => {
    const account = inject(AccountApi);
    return account.check().pipe(
        take(1), // Берём только первое значение
        map(() => true),
        catchError(() => of(true)),
    );
};
