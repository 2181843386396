<div *ngIf="isVisible"
     class="update-notification"
     [@slideInOut]>
    <div class="notification-content">
        <div class="notification-header">
            <h3>{{'TEXT_UPDATE_SITE_TITLE'|translate}}</h3>
            <button *ngIf="showDismiss"
                    class="close-button"
                    (click)="dismiss()">
                ✕
            </button>
        </div>
        <p class="notification-description">{{'TEXT_UPDATE_SITE_SUBTITLE'|translate}}</p>
        <div class="notification-actions">
            <button class="update-button" (click)="update()">
                <svg xmlns="http://www.w3.org/2000/svg"
                     width="16"
                     height="16"
                     viewBox="0 0 24 24"
                     fill="none"
                     stroke="currentColor"
                     stroke-width="2"
                     stroke-linecap="round"
                     stroke-linejoin="round">
                    <path d="M21.5 2v6h-6M21.34 15.57a10 10 0 1 1-.57-8.38"/>
                </svg>
                {{'TEXT_UPDATE_SITE_BUTTON'|translate}}
            </button>
        </div>
    </div>
</div>
