import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { animate, style, transition, trigger } from '@angular/animations';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'app-update-notification',
    standalone: true,
    imports: [
        CommonModule,
        TranslateModule,
    ],
    templateUrl: './update-notification.component.html',
    styleUrls: ['./update-notification.component.scss'],
    animations: [
        trigger('slideInOut', [
            transition(':enter', [
                style({transform: 'translateY(-100%)', opacity: 0}),
                animate('300ms ease-out', style({transform: 'translateY(0)', opacity: 1}))
            ]),
            transition(':leave', [
                animate('200ms ease-in', style({transform: 'translateY(-100%)', opacity: 0}))
            ])
        ]),
    ]
})
export class UpdateNotificationComponent {
    @Input() showDismiss: boolean = true;
    @Output() updateClicked = new EventEmitter<void>();
    @Output() dismissed = new EventEmitter<void>();

    isVisible = true;

    update(): void {
        this.updateClicked.emit();
    }

    dismiss(): void {
        this.isVisible = false;
        this.dismissed.emit();
    }
}
