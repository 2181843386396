import {
    Component,
    HostBinding,
    HostListener, Input, NgZone,
} from '@angular/core';
import { MobileMenuService } from '../../../../services/mobile-menu.service';
import { RouterLink } from '@angular/router';
import { LocalizeRouterModule } from '@gilsdav/ngx-translate-router';
import { TranslateModule } from '@ngx-translate/core';
import { IconComponent } from '../icon/icon.component';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'app-clients',
    templateUrl: './clients.component.html',
    styleUrls: ['./clients.component.scss'],
    standalone: true,
    imports: [
        RouterLink,
        LocalizeRouterModule,
        TranslateModule,
        IconComponent,
        CommonModule,
    ]
})
export class ClientsComponent {

    isOpen = false;

    @Input() isMenu: boolean = false;

    @HostBinding('class.clients') classClients = true;

    @HostBinding('class.clients--open') get classClientsOpen() {
        return this.isOpen;
    }

    @HostBinding('class.clients--menu') get classClientsMenu() {
        return this.isMenu;
    }

    @HostListener('click') handleOnClick() {
        this.onClick();
    }

    @HostListener('mouseenter') handleOnMouseEnter() {
        this.onMouseEnter();
    }

    @HostListener('mouseleave') handleOnMouseLeave() {
        this.onMouseLeave();
    }

    timerRun = false;

    constructor(public menu: MobileMenuService, private zone: NgZone) {
    }

    onClick() {
        if (!this.timerRun) {
            this.isOpen = !this.isOpen;
        }
    }

    onItemClick(): void {
        // this.isOpen = false;
        this.menu.close();
    }

    onMouseLeave(): void {
        this.isOpen = false;
    }

    onMouseEnter(): void {
        this.timerRun = true;
        this.zone.runOutsideAngular(() => {
            setTimeout(() => {
                this.zone.run(() => this.timerRun = false)
            }, 200);
        })

        this.isOpen = true;
    }
}
