import { Inject, Injectable, Optional } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { REQUEST } from '@nguniversal/express-engine/tokens';

@Injectable()
export class CookieSsrInterceptor implements HttpInterceptor {

  constructor(
      @Optional() @Inject(REQUEST) private httpRequest: any,
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
      if (this.httpRequest && this.httpRequest.headers.cookie) //If optional request is provided, we are server side
      {
          // console.log('cookie ssr', this.httpRequest.headers.cookie)
          request = request.clone(
              {
                  setHeaders:
                      {
                          Cookie: this.httpRequest.headers.cookie,
                      },
              });
      }
      return next.handle(request);
  }
}
