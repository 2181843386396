import { Component, HostBinding } from '@angular/core';
import { WishlistService } from '../../services/wishlist.service';
import { AccountApi } from '../../api';
import { CartService } from '../../services/cart.service';
import { UrlService } from '../../services/url.service';
import { LoginService } from '../../services/login.service';
import { map, Observable } from 'rxjs';


@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
    userName$: Observable<string | null> = this.account.user$.pipe(map(x => x ? `${x.firstName} ${x.lastName ? x.lastName : ''}` : null));

    @HostBinding('class.desktop-header') classDesktopHeader = true;

    constructor(
        private account: AccountApi,
        public wishlist: WishlistService,
        public cart: CartService,
        public url: UrlService,
        public loginService: LoginService,
    ) {}
}
