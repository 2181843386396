import { Injectable, NgZone } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { runOutsideAngular } from '@sentry/angular/zone';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
    private navigationStart$ = new BehaviorSubject<boolean>(false);

    constructor(private router: Router, private zone: NgZone) {
        this.zone.runOutsideAngular(() => {
            this.router.events.pipe(
                filter(event => event instanceof NavigationEnd),
                // startWith(this.router),
            ).subscribe(() => {
                this.zone.run(() => this.setStart())
            });
        })
    }

    setStart() {
        this.navigationStart$.next(true);
    }

    get isNavigationStart() {
        return this.navigationStart$.value;
    }
}
