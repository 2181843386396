import { NgModule, isDevMode } from '@angular/core';

// modules (angular)
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// modules
import { AppModule } from './app.module';

// components
import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';


@NgModule({
    imports: [
        // modules (angular)
        BrowserAnimationsModule,
        // modules
        AppModule,
        ServiceWorkerModule.register('ngsw-worker.js', {
          enabled: !isDevMode(),
          // Register the ServiceWorker as soon as the application is stable
          // or after 30 seconds (whichever comes first).
          registrationStrategy: 'registerWhenStable:30000'
        }),
    ],
    bootstrap: [AppComponent],
})
export class AppBrowserModule {}
