import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class HttpCacheService {
    private isHydratedSubject = new BehaviorSubject<boolean>(false);
    isHydrated$ = this.isHydratedSubject.asObservable();

    constructor() {
        // if (isPlatformBrowser(this.platformId)) {
        //     console.log('tcs: run on client')
        //     // Ждем первую успешную навигацию
        //     this.zone.runOutsideAngular(() => {
        //         this.router.events.pipe(
        //             filter(event => event instanceof NavigationEnd),
        //             take(1),
        //         ).subscribe(() => {
        //             this.zone.run(() => {
        //                 console.log('tcs: nav end')
        //                 this.isHydratedSubject.next(true);
        //             });
        //         });
        //     });
        // }
    }

    disable() {
        console.log('disable http cache')
        this.isHydratedSubject.next(true);
    }

    get isHydrated(): boolean {
        return this.isHydratedSubject.value;
    }
}
