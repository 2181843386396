import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import {
    AccountApi,
    EditProfileData,
    EditProfilePassword,
    GetJournalListOptions,
    GetOrdersListOptions, ResetPasswordData,
} from '../base';
import { BehaviorSubject, Observable, of, Subject, throwError } from 'rxjs';
import { User } from '../../interfaces/user';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { JournalList, OrdersList } from '../../interfaces/list';
import { Order, PaymentData } from '../../interfaces/order';
import { environment } from '../../../environments/environment';
import {
    HttpClient,
    HttpHeaders,
    HttpParams,
    HttpResponse,
} from '@angular/common/http';
import { CurrencyService } from '../../modules/currency/services/currency.service';
import { Balance } from '../../interfaces/balance';
import { UploadSubscription } from '../../interfaces/upload-subscription';
import { SocialAuthService } from '@abacritt/angularx-social-login';
import { B2bRequest } from '../../interfaces/b2b-request';
import { B2bData } from '../../interfaces/b2b-request-data';
import { reloadCurrentRoute } from '../../functions/utils';
import { Router } from '@angular/router';

@Injectable()
export class RestAccountApi extends AccountApi {
    // private _authWasChecked: boolean = false;

    private userSubject: BehaviorSubject<User | null>;
    private noSyncCartSubject$: BehaviorSubject<boolean> = new BehaviorSubject(false);

    private signInSubject = new Subject<void>();
    signIn$: Observable<void> = this.signInSubject.asObservable();

    private signOutSubject = new Subject<void>();
    signOut$: Observable<void> = this.signOutSubject.asObservable();

    private url = environment.apiHost;

    private _loggedInAsSocial = false;

    get user(): User | null {
        return this.userSubject.value;
    }

    get fullName(): string | null {
        if (this.user) {
            return `${this.user.lastName ?? ''} ${this.user.firstName}`;
        }
        return null;
    }

    get noSyncCart() {
        return this.noSyncCartSubject$.value;
    }

    readonly user$: Observable<User | null>;

    constructor(
        @Inject(PLATFORM_ID) private platformId: any,
        private http: HttpClient,
        private currency: CurrencyService,
        private socialAuthService: SocialAuthService,
        private router: Router,
    ) {
        super();

        if (isPlatformBrowser(this.platformId)) {
            const storedNoSyncCart = localStorage.getItem('no_sync_cart');
            this.noSyncCartSubject$.next(storedNoSyncCart ? JSON.parse(storedNoSyncCart) : false);
        }

        this.userSubject = new BehaviorSubject<User | null>(null);

        this.user$ = this.userSubject.asObservable();
    }

    check(): Observable<any> {
        // if (this._authWasChecked) return of(null);

        const url = `${this.url}/auth/check`;
        const headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this.http.get<User | null>(url, { headers: headers }).pipe(
            tap((user) => {
                this.setUser(user);
                // this._authWasChecked = true;
            }),
            catchError(err => {
                // this._authWasChecked = false;
                this.setUser(null);
                return throwError(() => err);
            }),
        );
    }

    refreshToken(): Observable<string> {
        const url = `${this.url}/auth/refresh-token`;
        return this.http.post(url, {}, { withCredentials: true }).pipe(
            switchMap((result: any) => {
                if ((result['status'] as string) === 'ok') {
                    const token = result['token'] as string;
                    this.userSubject.value!.accessToken = token;
                    this.saveUserValue();
                    return of(token);
                }
                return throwError(() => new Error('Unable to update token'));
                // return '';
            }),
            catchError((err) => {
                return throwError(() => err);
            }),
        );
    }

    signIn(email: string, password: string): Observable<User> {
        const url = `${this.url}/auth/login`;
        const body = {
            LoginForm: {
                username: email,
                password: password,
            },
        };
        const headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this.http
            .post<User>(url, JSON.stringify(body), {
                headers: headers,
                withCredentials: true,
            })
            .pipe(tap((user) => {
                this.setUser(user);
                this.signInSubject.next();
                reloadCurrentRoute(this.router);
            }));
    }

    signInWithGoogle(tokenId: string): Observable<User> {
        const url = `${this.url}/auth/sign-in-with-google`;
        const body = {
            tokenId,
        };
        const headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this.http
            .post<User>(url, JSON.stringify(body), {
                headers: headers,
                withCredentials: true,
            })
            .pipe(tap((user) => {
                this.setUser(user);
                this.signInSubject.next();
                reloadCurrentRoute(this.router);
            }));
    }

    set loggedInAsSocial(value: boolean) {
        this._loggedInAsSocial = value;
    }

    signUp(firstName: string, lastName: string, phone: string, email: string, password: string): Observable<User> {
        const url = `${this.url}/auth/register`;
        const body = {
            SignupApiForm: {
                first_name: firstName,
                last_name: lastName,
                phone: phone,
                email: email,
                password: password,
            },
        };
        const headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this.http
            .post<User>(url, JSON.stringify(body), {
                headers: headers,
                withCredentials: true,
            })
            .pipe(tap((user) => {
                this.setUser(user);
                this.signInSubject.next();
                reloadCurrentRoute(this.router);
            }));
    }

    signOut(): Observable<any> {
        const url = `${this.url}/auth/sign-out`;
        return this.http.post(url, {}).pipe(tap(() => {
            this.setUser(null);
            if (this._loggedInAsSocial) {
                this.socialAuthService.signOut(true);
            }
            this.currency.resetToDefault();
            this.signOutSubject.next();
        }));
    }

    loginAs(token: string, noSyncCart: boolean): Observable<User> {
        const url = `${this.url}/auth/login-as`;
        const body = {
            token,
        };
        const headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this.http
            .post<User>(url, JSON.stringify(body), {
                headers: headers,
                withCredentials: true,
            })
            .pipe(tap((user) => {
                this.setUser(user);
                this.signInSubject.next();
            }));
    }

    editProfile(data: EditProfileData): Observable<User> {
        const url = `${this.url}/auth/edit-profile`;
        const body = {
            EditProfileForm: {
                first_name: data.firstName,
                last_name: data.lastName,
                phone: data.phone,
                email: data.email,
            },
        };
        const headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this.http.post<User>(url, JSON.stringify(body), { headers: headers })
            .pipe(tap((user) => this.setUser(user)));
    }

    editProfilePassword(data: EditProfilePassword): Observable<any> {
        const url = `${this.url}/auth/change-password`;
        const headers = new HttpHeaders().set('Content-Type', 'application/json');
        const body = {
            ChangePasswordForm: {
                oldPassword: data.currentPassword,
                newPassword: data.newPassword,
                confirmPassword: data.confirmPassword,
            },
        };
        return this.http.post<User>(url, JSON.stringify(body), { headers: headers });
    }

    getOrdersList(options?: GetOrdersListOptions): Observable<OrdersList> {
        // return getOrdersList(options);
        let params = new HttpParams();
        params = params.append('per-page', options?.limit ? options.limit : 16);
        if (options?.page) {
            params = params.append('page', options.page);
        }

        const url = `${this.url}/order/orders`;

        return this.http.get<OrdersList>(url, { params: params });
    }

    getOrderByToken(token: string): Observable<Order> {
        // return getOrderById(id);
        let params = new HttpParams().set('token', token);

        const url = `${this.url}/order/order`;

        return this.http.get<Order>(url, { params: params });
    }

    getCheckoutOrder(id: number): Observable<Order> {
        let params = new HttpParams().set('checkoutId', id);

        const url = `${this.url}/order/checkout-order`;

        return this.http.get<Order>(url, { params: params });
    }

    getPaymentData(id: number, fromListOrders: boolean): Observable<PaymentData> {
        let params = new HttpParams()
            .set('checkoutId', id)
            .set('fromListOrders', fromListOrders);

        const url = `${this.url}/order/payment-data`;

        return this.http.get<PaymentData>(url, { params: params });
    }

    getJournal(options: GetJournalListOptions): Observable<JournalList> {
        let params = new HttpParams();
        params = params.append('type', options.type);
        if (options?.page) {
            params = params.append('page', options.page);
        }

        const url = `${this.url}/account/journals`;

        return this.http.get<JournalList>(url, { params: params });
    }

    getBalance(): Observable<Balance> {
        const url = `${this.url}/account/balance`;
        return this.http.get<Balance>(url);
    }

    getB2bRequest(): Observable<B2bRequest> {
        const url = `${this.url}/account/check-b2b-request`;
        return this.http.get<B2bRequest>(url);
    }

    sendPaymentInform(cartNumber: string, amount: string): Observable<boolean> {
        const url = `${this.url}/account/payment-inform`;
        const body = {
            cartNumber: cartNumber,
            paymentSum: amount,
        };
        const headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this.http
            .post(url, JSON.stringify(body), { headers: headers })
            .pipe(
                switchMap((result: any) => {
                    if (result['success'] as boolean) {
                        return of(true);
                    }
                    return of(false);
                }),
                catchError(() => of(false)),
            );
    }

    print(id: string): Observable<any> {
        let params = new HttpParams().set('id', id);
        const url = `${this.url}/account/print`;
        return this.http.get<HttpResponse<Blob>>(url, {
            params: params,
            responseType: 'blob' as 'json',
        });
    }

    printReconciliationAct(startDate: string, endDate: string): Observable<any> {
        let params = new HttpParams()
            .set('startDate', startDate)
            .set('endDate', endDate);
        const url = `${this.url}/account/print-reconciliation-act`;
        return this.http.get<HttpResponse<Blob>>(url, {
            params: params,
            responseType: 'blob' as 'json',
        });
    }

    private setUser(user: User | null, noSyncCart = false): void {
        this.noSyncCartSubject$.next(noSyncCart);

        if (user) {
            this.currency.set(user.currency);
        }

        if (isPlatformBrowser(this.platformId)) {
            localStorage.setItem('user', JSON.stringify(user));
            localStorage.setItem('no_sync_cart', JSON.stringify(noSyncCart));
        }

        this.userSubject.next(user);
    }

    private saveUserValue(): void {
        const user = this.userSubject.value;
        localStorage.setItem('user', JSON.stringify(user));
    }

    getUploadSubscription(): Observable<UploadSubscription> {
        const url = `${this.url}/account/upload-subscription`;
        return this.http.get<UploadSubscription>(url);

        // return new Observable<UploadSubscription>((observer) => {
        //     setTimeout(() => {
        //         observer.next({
        //             email: 'yanayushko@gmail.com',
        //             format: 'csv',
        //             language: ['UK', 'RU'],
        //             onlyAvailable: true,
        //             day: ['1', '2', '3'],
        //             status: 'disable',
        //         });
        //         observer.complete();
        //     }, 2000);
        // });
    }

    editUploadSubscription(data: UploadSubscription): Observable<UploadSubscription> {
        const url = `${this.url}/account/upload-subscription`;
        const headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this.http.post<UploadSubscription>(url, JSON.stringify(data), { headers: headers });
    }

    forgotPassword(email: string): Observable<any> {
        const url = `${this.url}/auth/forgot-password`;
        const headers = new HttpHeaders().set('Content-Type', 'application/json');
        const body = {
            PasswordResetRequestForm: {
                email: email,
            },
        }
        return this.http.post(url, JSON.stringify(body), { headers: headers });
    }

    sendB2bRequest(data: B2bData): Observable<B2bData> {
        const url = `${this.url}/account/send-b2b-request`;
        const headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this.http.post<B2bData>(url, JSON.stringify(data), { headers: headers });
    }

    resetPassword(data: ResetPasswordData): Observable<User> {
        const url = `${this.url}/auth/reset-password`;
        const headers = new HttpHeaders().set('Content-Type', 'application/json');

        let params = new HttpParams().set('token', data.token);

        const body = {
            PargoResetPasswordForm: {
                password: data.newPassword,
            },
        }
        return this.http.post<User>(url, JSON.stringify(body), { headers: headers, params: params })
            .pipe(tap((user) => this.setUser(user)));
    }

    sendLastActivity(): Observable<any> {
        const url = `${this.url}/account/last-activity`;
        const headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this.http.get(url, { headers });
    }
}
