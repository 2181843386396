import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'app-icon',
    templateUrl: './icon.component.html',
    styleUrls: ['./icon.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
    ],
})
export class IconComponent {
    @Input() icon!: string;

    constructor() { }

    iconSizes: { [key: string]: { width: string; height: string; sprite: string } } = {
        'arrow-down-9x6': { width: '9', height: '6', sprite: '0' },
        'arrow-down-sm-7x5': { width: '7', height: '5', sprite: '0' },
        'arrow-rounded-down-9x6': { width: '9', height: '6', sprite: '0' },
        'arrow-rounded-down-12x7': { width: '12', height: '7', sprite: '0' },
        'arrow-rounded-left-6x9': { width: '6', height: '9', sprite: '0' },
        'arrow-rounded-left-7x11': { width: '7', height: '11', sprite: '0' },
        'arrow-rounded-right-6x9': { width: '6', height: '9', sprite: '0' },
        'arrow-rounded-right-7x11': { width: '7', height: '11', sprite: '0' },
        'car-20': { width: '33', height: '22', sprite: '0' },
        'cart-20': { width: '20', height: '20', sprite: '0' },
        'cart-32': { width: '32', height: '32', sprite: '0' },
        'cart-empty': { width: '160', height: '160', sprite: '0' },
        'check-9x7': { width: '9', height: '7', sprite: '0' },
        'check-12x9': { width: '12', height: '9', sprite: '0' },
        'check-100': { width: '100', height: '100', sprite: '0' },
        'compare-16': { width: '16', height: '16', sprite: '0' },
        'cross-9-light': { width: '9', height: '9', sprite: '0' },
        'cross-10': { width: '10', height: '10', sprite: '0' },
        'cross-12': { width: '12', height: '12', sprite: '0' },
        'cross-20': { width: '20', height: '20', sprite: '0' },
        'filters-16': { width: '16', height: '16', sprite: '0' },
        'fit': { width: '13', height: '13', sprite: '0' },
        'heart-20': { width: '20', height: '20', sprite: '0' },
        'heart-32': { width: '32', height: '32', sprite: '0' },
        'info-50': { width: '24', height: '24', sprite: '0' },
        'layout-grid-16': { width: '16', height: '16', sprite: '0' },
        'lay-grid-17': { width: '17', height: '15', sprite: '0' },
        'lay-list-17': { width: '17', height: '13', sprite: '0' },
        'layout-grid-with-details-16': { width: '16', height: '16', sprite: '0' },
        'layout-list-16': { width: '16', height: '16', sprite: '0' },
        'layout-table-16': { width: '16', height: '16', sprite: '0' },
        'menu-16x12': { width: '16', height: '12', sprite: '0' },
        'menu-18x14': { width: '18', height: '14', sprite: '0' },
        'no-fit': { width: '13', height: '13', sprite: '0' },
        'person-20': { width: '20', height: '20', sprite: '0' },
        'person-19': { width: '19', height: '19', sprite: '0' },
        'quickview-16': { width: '16', height: '16', sprite: '0' },
        'recycle-bin-16': { width: '16', height: '16', sprite: '0' },
        'search-20': { width: '20', height: '20', sprite: '0' },
        'zoom-in-24': { width: '24', height: '24', sprite: '0' },
        'trip-route' : { width: '15', height: '17', sprite: '0' },
        'category-25': { width: '25', height: '20', sprite: '1' },
        'cart-19': { width: '19', height: '19', sprite: '1' },
        'wait-8': { width: '8', height: '11', sprite: '1' },
        'bom-49': { width: '49', height: '49', sprite: '1' },
        'copy-18': { width: '18', height: '18', sprite: '1' },
        'arrow-prev-20': { width: '20', height: '10', sprite: '1' },
        'arrow-next-20': { width: '20', height: '10', sprite: '1' },
        'guarantee-20': { width: '22', height: '22', sprite: '1' },
        'delivery-20': { width: '22', height: '22', sprite: '1' },
        'about-us-20': { width: '22', height: '22', sprite: '1' },
        'offer-20': { width: '22', height: '22', sprite: '1' },
        'blog-20': { width: '22', height: '22', sprite: '1' },
        'viber-20': { width: '20', height: '20', sprite: '1' },
        'telegram-20': { width: '20', height: '20', sprite: '1' },
        'phone-20': { width: '20', height: '20', sprite: '1' },
        'lang-14': { width: '14', height: '14', sprite: '1' },
        'load-19': { width: '19', height: '17', sprite: '1' },
        'close-16': { width: '16', height: '16', sprite: '1' },
        'close-16-no-fill': { width: '16', height: '16', sprite: '1' },
        'delivery-121': { width: '121', height: '121', sprite: '1' },
        'guarantee-121': { width: '121', height: '121', sprite: '1' },
        'assortment-121': { width: '121', height: '121', sprite: '2' },
        'confidence-121': { width: '121', height: '121', sprite: '2' },
        'logo': { width: '165', height: '51', sprite: '2' },
        'mail-20': { width: '20', height: '20', sprite: '2' },
        'geo-20': { width: '20', height: '20', sprite: '2' },
        'user-14': { width: '12', height: '14', sprite: '2' },
        'twitter-45': { width: '45', height: '45', sprite: '2' },
        'insta-45': { width: '45', height: '45', sprite: '2' },
        'linkedin-45': { width: '45', height: '45', sprite: '2' },
        'facebook-45': { width: '45', height: '45', sprite: '2' },
        'arrow-37': { width: '37', height: '37', sprite: '2' },
        'menu-16': { width: '29', height: '16', sprite: '2' },
        'cart-mobile-34': { width: '34', height: '34', sprite: '2' },
        'arrow-category': { width: '12', height: '23', sprite: '2' },
        'facebook-23': { width: '23', height: '23', sprite: '2' },
        'google-23': { width: '23', height: '23', sprite: '2' },
        'receipt-24': { width: '800', height: '800', sprite: '2' },
        'schedule-20': { width: '20', height: '20', sprite: '2' },
    };
}
